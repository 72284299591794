import React, { useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import Lightbox from 'react-image-lightbox';
import Divider from '../components/common/divider';
import DividerParalax from '../components/index/divider-paralax';
import Layout from '../components/site-wide/layout/layout';
import constants from '../utils/constants';

const getNumberFromName = (src) => {
	const split = src.split('/');
	const name = split[split.length - 1];

	return parseInt(name.split('.')[0]);
};

const trimImages = (imageSet) => {
	return imageSet.map(({ node }) => {
		return {
			src: node.childImageSharp.resize.src,
			// thumbnail: node.childImageSharp.resize.src,
			width: node.childImageSharp.resize.width,
			height: node.childImageSharp.resize.height,
		};
	});
};

const extractLinks = (images) => {
	return images
		.map((node) => {
			return node.src;
		})
		.sort((a, b) => {
			return getNumberFromName(a) - getNumberFromName(b);
		});
};

const MenuPage = ({ data }) => {
	const images = trimImages(data.images.edges);
	const imageLinks = extractLinks(images);

	const [isLigthboxOpen, setIsLightboxOpen] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);

	return (
		<Layout
			title={constants.MENU.META.TITLE}
			description={constants.MENU.META.DESCRIPTION}
		>
			<Container className='mt-5 pt-5'>
				<div className='text-center'>
					<h1>Меню</h1>
					<Divider />
					<p>Разгледай нашето разнообразно меню.</p>
				</div>
				<Row>
					<div className='w-100 text-center px-3'>
						<hr />
						<p>
							Доставяме до следните квартали: <b>Белите брези</b>, <b>Борово</b>
							, <b>Гоце Делчев</b>, <b>Иван Вазов</b>, <b>Манастирски ливади</b>
							, <b>Мотописта</b>, <b>Стрелбище</b>, <b>Красно село</b>.
							<br />
							Вземи за вкъщи или от място на адрес:{' '}
							<a
								href={constants.GOOGLE_MAPS_LINK}
								target='_blank'
								rel='noreferrer'
								className='text-gold'
							>
								{constants.ADDRESS}
							</a>
							.
						</p>
						<p>Работно време за доставки - Понеделник-Неделя - 11:00 - 23:30</p>

						<hr />
					</div>
				</Row>
				{isLigthboxOpen && (
					<>
						<Lightbox
							mainSrc={imageLinks[photoIndex]}
							nextSrc={imageLinks[(photoIndex + 1) % imageLinks.length]}
							prevSrc={
								imageLinks[
									(photoIndex + imageLinks.length - 1) % imageLinks.length
								]
							}
							onCloseRequest={() => {
								setIsLightboxOpen(false);
							}}
							onMovePrevRequest={() => {
								setPhotoIndex(
									(photoIndex + imageLinks.length - 1) % imageLinks.length
								);
							}}
							onMoveNextRequest={() => {
								setPhotoIndex((photoIndex + 1) % imageLinks.length);
							}}
						/>
					</>
				)}

				<div className='mt-5'>
					<Row>
						{imageLinks.map((imgSrc, index) => (
							<Col lg={3}>
								<Image
									className='mb-5 menu-gallery'
									fluid
									src={imgSrc}
									alt='Меню'
									onClick={() => {
										setPhotoIndex(index);
										setIsLightboxOpen(true);
									}}
								/>
							</Col>
						))}
					</Row>

					<Row></Row>
				</div>
			</Container>

			<DividerParalax>
				<Container>
					<Row className='w-100 text-center text-white pt-5'>
						<div className='mt-5 pt-5 w-100'>
							<h3 className='text-white'>Поръчай храна за вкъщи</h3>
							<a
								href={`tel:${constants.TELEPHONE}`}
								className='btn btn-primary mt-3'
							>
								{constants.TELEPHONE}
							</a>
						</div>
					</Row>
				</Container>
			</DividerParalax>
		</Layout>
	);
};

export const query = graphql`
	query MenuQuery {
		images: allFile(
			filter: { sourceInstanceName: { eq: "images-menu" } }
			sort: { fields: [name], order: ASC }
		) {
			edges {
				node {
					absolutePath
					childImageSharp {
						resize(height: 1000, quality: 100) {
							src
							originalName
							width
							height
						}
					}
				}
			}
		}
	}
`;

export default MenuPage;
